import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordApiService {
  private readonly apiUrl = environment.apiConfUrl;

  constructor(private http: HttpClient) {}

  forgot(email: string): Observable<any> {
    const url = this.buildFullUrl('forgot');
    return this.http.post(url, { email });
  }

  validateToken(token: string): Observable<any> {
    const url = `${this.buildFullUrl('validate')}/${token}`;
    return this.http.get(url);
  }

  reset(password: string, token: string): Observable<any> {
    const url = this.buildFullUrl('reset');
    return this.http.post(url, { password, token });
  }

  private buildFullUrl(path: string): string {
    return `${this.apiUrl}/password/${path}`;
  }
}
