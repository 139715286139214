import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AuthApi } from '@core/states/auth/auth.api';
import * as AuthActions from '@core/states/auth/auth.actions';
import {
  catchError,
  exhaustMap,
  map,
  Observable,
  of,
  tap,
  throwError,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import { selectAuthUser } from '@core/states/auth/auth.selectors';
import { Router } from '@angular/router';
import { LOGIN_PATH } from '@features/auth/auth.routes';
import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { LoadStatus } from '@core/states/auth/interfaces/load-status';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authApi: AuthApi,
    private router: Router
  ) {}

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.login),
      tap(() =>
        this.store.dispatch(
          AuthActions.setLoadStatus({ loadStatus: LoadStatus.LOADING })
        )
      ),
      exhaustMap(({ email, password, rememberMe }) =>
        this.authApi.login(email, password, rememberMe).pipe(
          map(user => AuthActions.setUser({ user })),
          tap(() =>
            this.store.dispatch(
              AuthActions.setLoadStatus({ loadStatus: LoadStatus.DATA })
            )
          ),
          tap(() => this.router.navigate(['/users'])),
          catchError(error => of(AuthActions.setError({ error })))
        )
      )
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() =>
        this.store.dispatch(
          AuthActions.setLoadStatus({ loadStatus: LoadStatus.LOADING })
        )
      ),
      exhaustMap(() =>
        this.authApi.logout().pipe(
          map(() => AuthActions.removeUser()),
          tap(() =>
            this.store.dispatch(
              AuthActions.setLoadStatus({ loadStatus: LoadStatus.NOT_LOADED })
            )
          ),
          tap(() => this.router.navigate([LOGIN_PATH])),
          catchError(error => of(AuthActions.setError({ error })))
        )
      )
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updatePassword),
      tap(() =>
        this.store.dispatch(
          AuthActions.setLoadStatus({ loadStatus: LoadStatus.UPDATING })
        )
      ),
      exhaustMap(({ oldPassword, newPassword }) => {
        const authUser = this.store.selectSignal(selectAuthUser)() as AuthUser;
        return this.authApi
          .updatePassword(authUser.id, { oldPassword, newPassword })
          .pipe(
            map(user => AuthActions.setUser({ user })),
            tap(() =>
              this.store.dispatch(
                AuthActions.setLoadStatus({ loadStatus: LoadStatus.DATA })
              )
            ),
            catchError(error => of(AuthActions.setError({ error })))
          );
      })
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateUser),
      tap(() =>
        this.store.dispatch(
          AuthActions.setLoadStatus({ loadStatus: LoadStatus.UPDATING })
        )
      ),
      exhaustMap(({ user }) => {
        const authUser = this.store.selectSignal(selectAuthUser)() as AuthUser;
        return this.authApi.updateUser(authUser.id, user).pipe(
          map(user => AuthActions.setUser({ user })),
          tap(() =>
            this.store.dispatch(
              AuthActions.setLoadStatus({ loadStatus: LoadStatus.DATA })
            )
          ),
          catchError(error => of(AuthActions.setError({ error })))
        );
      })
    );
  });

  isLoggedIn$ = (skipErrorRedirect = false): Observable<boolean> => {
    return this.getCurrentUser$(skipErrorRedirect).pipe(map(user => !!user));
  };

  private getCurrentUser$ = (
    skipErrorRedirect = false
  ): Observable<AuthUser | undefined> => {
    const currentUser = this.store.selectSignal(selectAuthUser)();

    if (currentUser) {
      return of(currentUser);
    }

    this.store.dispatch(
      AuthActions.setLoadStatus({ loadStatus: LoadStatus.LOADING })
    );
    return this.authApi.getUser(skipErrorRedirect).pipe(
      tap(() =>
        this.store.dispatch(
          AuthActions.setLoadStatus({ loadStatus: LoadStatus.DATA })
        )
      ),
      tap(user => this.store.dispatch(AuthActions.setUser({ user }))),
      catchError(error => {
        this.store.dispatch(AuthActions.setError(error));
        return throwError(() => error);
      })
    );
  };
}
