<form
  [formGroup]="formGroup"
  class="flex flex-col"
  appEnterKey
  (enterKey)="onSubmit()">
  <mat-form-field class="mb-3 w-full" appearance="outline" floatLabel="always">
    <mat-label>Email</mat-label>
    <input
      matInput
      formControlName="email"
      data-cy="email"
      [placeholder]="'Your email'" />
    @if (formGroup.get('email')?.invalid) {
      <mat-error>
        @if (formGroup.get('email')?.getError('email')) {
          Email is invalid
        } @else {
          Email is required
        }
      </mat-error>
    }
  </mat-form-field>

  <app-default-button
    [stretch]="true"
    [loading]="loading()"
    (clickEvent)="onSubmit()"
    >Send email</app-default-button
  >
</form>
