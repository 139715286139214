<form
  [formGroup]="formGroup"
  class="flex flex-col"
  appEnterKey
  (enterKey)="onSubmit()">
  <!-- New password -->
  <div class="mb-10">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>New password</mat-label>
      <input matInput formControlName="newPassword" [type]="'password'" />
      @if (
        formGroup.controls['newPassword'].invalid &&
        formGroup.controls['newPassword'].hasError('required')
      ) {
        <mat-error> New password is required </mat-error>
      }
    </mat-form-field>
    <app-password-rule-validator
      [errors]="
        formGroup.controls['newPassword'].errors
      "></app-password-rule-validator>
  </div>

  <!-- Verified password -->
  <mat-form-field class="mb-3 w-full" appearance="outline">
    <mat-label>Verify password</mat-label>
    <input matInput formControlName="verifyPassword" [type]="'password'" />
    @if (
      formGroup.controls['verifyPassword'].invalid &&
      formGroup.controls['verifyPassword'].hasError('required')
    ) {
      <mat-error> Password is required </mat-error>
    }
    @if (
      formGroup.controls['verifyPassword'].invalid &&
      formGroup.controls['verifyPassword'].hasError('notMatch')
    ) {
      <mat-error> Password does not match </mat-error>
    }
  </mat-form-field>

  <app-default-button
    class="flex flex-row-reverse"
    [loading]="loading()"
    (clickEvent)="onSubmit()"
    [stretch]="true">
    Submit
  </app-default-button>
</form>
