<app-card class="flex">
  <div class="mb-4 font-medium">
    <span
      class="heading-title--small underline-primary"
      i18n="@@appHeadingTitleLogin"
      >Login</span
    >
  </div>
  <app-login-form
    [loading]="(loadStatus$ | async) === AuthLoadStatus.LOADING"
    (submitEvent)="authenticate($event)"></app-login-form>
</app-card>
