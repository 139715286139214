import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appEnterKey]',
})
export class EnterKeyDirective {
  @Output() enterKey = new EventEmitter<void>();

  @HostListener('keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    if (event.key !== 'Enter') {
      return;
    }
    this.enterKey.emit();
  }
}
