import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultButtonComponent } from '@shared/components/buttons/default-button/default-button.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PasswordRulesValidator } from '@core/helpers/password-rules/password-rules-validator';
import { PasswordMatchValidator } from '@core/helpers/password-rules/password-match-validator';
import { PasswordRuleValidatorComponent } from '@features/profile/components/forms/profile-change-password-form/change-password-form/component/password-rule-validator.component';
import { EnterKeyDirective } from '@core/directives/enter-key.directive';

type PasswordResetFormGroupType = {
  newPassword: FormControl<string | null>;
  verifyPassword: FormControl<string | null>;
};

export type PasswordResetFormResultType = {
  password: string;
};

@Component({
  standalone: true,
  selector: 'app-password-reset-form',
  imports: [
    CommonModule,
    DefaultButtonComponent,
    FormsModule,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    PasswordRuleValidatorComponent,
    EnterKeyDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './password-reset-form.component.html',
})
export class PasswordResetFormComponent implements OnInit {
  /**
   * Sets form in loading state
   */
  loading = input.required<boolean>();

  /**
   * Emits form value when user has submitted the form
   * Does not submit if form is in loading mode or form is invalid
   */
  @Output() submitted = new EventEmitter<PasswordResetFormResultType>();

  formGroup: FormGroup<PasswordResetFormGroupType>;
  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        newPassword: ['', [Validators.required, PasswordRulesValidator]],
        verifyPassword: ['', [Validators.required]],
      },
      {
        validators: PasswordMatchValidator<PasswordResetFormGroupType>(
          'newPassword',
          'verifyPassword'
        ),
      }
    );
  }

  onSubmit() {
    if (this.formGroup.invalid || this.loading()) {
      return;
    }
    // Because of the validators newPassword field must be defined
    this.submitted.emit({ password: this.formGroup.value.newPassword! });
  }
}
