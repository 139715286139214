import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@core/states/app.state';
import * as AuthActions from '@core/states/auth/auth.actions';
import { ACCESS_DENIED_PATH, LOGIN_PATH } from '@features/auth/auth.routes';

export const SkipErrorRedirectKey = 'X-Skip-Error-Redirect';
export const SkipErrorRedirectHeader = new HttpHeaders({
  [SkipErrorRedirectKey]: '',
});

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const redirect = !request.headers.has(SkipErrorRedirectKey);

    // Remove custom header, because not supported from backend
    const headers = request.headers.delete(SkipErrorRedirectKey);
    request = request.clone({ headers });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // handle 401 errors (unauthorized)
          this.store.dispatch(AuthActions.removeUser());

          if (window.location.pathname !== LOGIN_PATH && redirect) {
            // implement this method in AuthService
            this.router.navigate([LOGIN_PATH]);
          }
        } else if (error.status === 403) {
          // handle 403 errors (forbidden)
          this.router.navigate([ACCESS_DENIED_PATH]);
        }
        // If it's not a 401 or 403 error, just throw it again
        return throwError(() => error);
      })
    );
  }
}
