import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthEffects } from '@core/states/auth/auth.effects';

/**
 * Redirects to selected route if user is authenticated.
 * If user is not authenticated it will stay on login page
 */
export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const urlTree = router.createUrlTree(['']);
  const authEffects = inject(AuthEffects);

  return authEffects.isLoggedIn$(true).pipe(
    map(isLoggedIn => (isLoggedIn ? urlTree : true)),
    catchError(() => of(true))
  );
};
