import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PasswordForgottenComponent } from '@features/auth/components/password-forgotten/password-forgotten.component';
import { authGuard } from '@features/auth/auth.guard';

export const LOGIN_PATH = '/auth/login';
export const ACCESS_DENIED_PATH = '/access-denied';

export const AUTH_ROUTES: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [authGuard],
  },
  {
    path: 'password-forgotten',
    loadComponent: () => PasswordForgottenComponent,
  },
  {
    path: 'password-reset/:token',
    loadComponent: () => PasswordResetComponent,
  },
];
