import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { Observable } from 'rxjs';
import { CardComponent } from '@shared/components/card/card.component';
import { Store } from '@ngrx/store';
import { login } from '@core/states/auth/auth.actions';
import * as AuthSelectors from '@core/states/auth/auth.selectors';
import { AppState } from '@core/states/app.state';
import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { LoadStatus } from '@core/states/auth/interfaces/load-status';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, AlertComponent, CardComponent, LoginFormComponent],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loadStatus$: Observable<LoadStatus> = this.store.select(
    AuthSelectors.selectLoadStatus
  );
  error$: Observable<string | null | undefined> = this.store.select(
    AuthSelectors.selectErrorMessage
  );

  constructor(
    private store: Store<AppState>,
    private notifications: NotificationService
  ) {}

  ngOnInit(): void {
    this.error$.subscribe(error => {
      if (error) {
        this.notifications.showError(error);
      }
    });
  }

  authenticate = (event: Partial<AuthUser>) => {
    this.store.dispatch(
      login({
        email: event.email ?? '',
        password: event.password ?? '',
        rememberMe: event.rememberMe,
      })
    );
  };
  protected readonly AuthLoadStatus = LoadStatus;
}
