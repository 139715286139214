<form
  [formGroup]="formGroup"
  class="flex flex-col !pb-0"
  appEnterKey
  (enterKey)="submit()">
  <mat-form-field class="mb-3 w-full" appearance="outline" floatLabel="always">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" data-cy="email" />
    @if (formGroup.get('email')?.invalid) {
      <mat-error>
        @if (formGroup.get('email')?.getError('email')) {
          Email is invalid
        } @else {
          Email is required
        }
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field class="group w-full" appearance="outline" floatLabel="always">
    <mat-label>Password</mat-label>
    <input
      matInput
      formControlName="password"
      [type]="hidePassword() ? 'password' : 'text'"
      data-cy="password" />
    <fa-icon
      matSuffix
      class="input__suffix"
      [icon]="iconClass()"
      (click)="hidePassword.set(!hidePassword())" />
  </mat-form-field>

  <div class="mb-16 flex items-center justify-between">
    <mat-checkbox color="primary" formControlName="_remember_me">
      <span class="ml-1 w-max">Remember me</span>
    </mat-checkbox>

    <!-- TODO: Disabled for now until feature completion -->
    <a
      class="text-center text-sm hover:text-primary-400"
      [routerLink]="['..', 'password-forgotten']"
      >Password forgotten?</a
    >
  </div>

  <app-default-button
    [stretch]="true"
    [loading]="loading()"
    (clickEvent)="submit()"
    data-cy="submit"
    >Login<fa-icon
      [icon]="['fas', 'arrow-right']"
      class="text-white transition-transform duration-300 ease-in-out group-hover:translate-x-2 dark:text-primary-500"></fa-icon
  ></app-default-button>
</form>
